import {applyMiddleware, createStore} from 'redux';
import {composeWithDevTools} from "@redux-devtools/extension";
import {useMemo} from "react";
import {MOBILE_WIDTH} from "lib/consts";

// Fill this with all the things //
const initialState = {
    isMobile: typeof window !== 'undefined' && window.innerWidth < MOBILE_WIDTH,
}

let store;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'IS_MOBILE':
            return {...state, isMobile: action.isMobile };
        default:
            return state;
    }
};


function initStore(preloadedState = initialState) {
    return createStore(
        reducer,
        preloadedState,
        composeWithDevTools(applyMiddleware()) // For use with the redux dev tool (Which you should get if you don't have it) //
    )
}

export const initializeStore = (preloadedState) => {
    let _store = store ?? initStore(preloadedState)

    // After navigating to a page with an initial Redux state, merge that state //
    // with the current state in the store, and create a new store //
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        })

        store = undefined
    }

    // For server-side stuff, always create a new store //
    if (typeof window === 'undefined') {
        return _store
    }

    if (!store) {
        store = _store
    }

    return _store
}

export function useStore(initialState) {
    // Use memoized store. Only give new store if values have changed //
    return useMemo(() => initializeStore(initialState), [initialState])
}
