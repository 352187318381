
export default function DevErrors({fatalError: {html, message, data}}){
    if(html){
        return <div dangerouslySetInnerHTML={{ __html: html }}></div>
    }

    return <div>
        <h1>Component Returned Errors: {message}</h1>
        <pre>{JSON.stringify(data,null, 4)}</pre>
    </div>

}