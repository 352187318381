import 'assets/scss/app.scss';
import 'react-calendar/dist/Calendar.css';
import 'assets/scss/vendor.scss'; // Should be loaded after vendor css is imported //
import { useStore } from 'lib/store';
import {Provider} from "react-redux";
import TagManager from 'react-gtm-module';
import {useEffect} from 'react';
import DevErrors from "components/DevErrors";

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
    if(process.env.APP_ENV !== 'prod' && pageProps.hasOwnProperty('fatalError')){
        return <DevErrors {...pageProps} />
    }
    // console.log(process.env.APP_ENV);
    // console.log("pageProps", pageProps);
    const store = useStore({isMobile:true});
    const tagManagerArgs = {
        gtmId: 'GTM-W9QHGH',
        dataLayerName: 'dataLayer'
    }
    useEffect(()=>{
            TagManager.initialize(tagManagerArgs)
            // console.log('loaded GTM')
    }, [])

    return (
        <Provider store={store}>
            <Component {...pageProps} />
        </Provider>
    )
}
